import React, { useState }  from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1, H2, H3, MobileH1, Paragraph } from '../../styles/fonts';
import GraphicTechnologies from "./../../images/entire-graphic-with-lines.svg"
import IconOptimizer from "./../../images/ic_optimizer.svg"
import IconPowerPlant from "./../../images/ic_powerplant.svg"
import IconDerms from "./../../images/ic_derms.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const OuterContainer = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:  auto;
    height: 100%;
    margin-top: 102px;
    max-width: 1198px;
    min-width: 1111px;
    justify-self: center;
    margin-right: 30px;
    margin-left: 30px;
    ${media.lessThan("743px")`
        width: 91%;
        max-width: 714px;
        min-width: 290px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 50px;
        justify-self: flex-start;
    `}
    ${media.between("744px", "large")`
        width: 100%;
        max-width: 1130px;
        min-width: 704px;
        margin-left: 0px;
        margin-right: 0px;
        justify-self: flex-start;
    `}
`;

const H2Responsive = styled(H2)`
        color: ${COLORS.MediumBlue};
    ${media.lessThan("743px")`
        width: 90%;
        min-width: 292px;
        max-width: 500px;
        font-size: 30px;
        margin-right: 15px;
        margin-bottom: 45px;
    `}
    ${media.between("744px", "large")`
        width: 90%;
        min-width: 632px;
        margin-left: 20px;
        margin-right: 20px;
    `}
    ${media.greaterThan("large")`
        width: 900px;
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:  auto;
    max-width: 1198px;
    height: 100%;
    margin-top: 34px;
    justify-content: space-between;
    ${media.lessThan("743px")`
        flex-wrap: wrap-reverse;
        margin-top: 0px;
        justify-content: space-between;
        min-width: 290px;
        width: 100%;
        max-width: 704px;
    `}
    ${media.between("744px", "large")`
        justify-content: space-between;
        width: 100%;
        min-width: 704px;
        max-width: 1130px;
        margin-left: 20px;
        margin-right: 20px;
    `}
`;

const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
    max-width: 588.63px;
    ${media.lessThan("402px")`
        width: 100%;
        min-width: 290px;
        align-self: center;
        justify-content: flex-start;
        `}
        ${media.between("402px", "744px")`
        justify-content: flex-start;
        width: 100%;
    `}
    ${media.between("744px", "large")`
        width: 50%;
        max-width: 565px;
    `}
`

const Graphic = styled.img`
    max-width: 588.63px;
    height: auto;
    align-self: start;
    ${media.lessThan("402px")`
        min-width: 290px;
        width: 100%;
        max-width: 362px;
        align-self: center;
        `}
        ${media.between("402px", "744px")`
        width: 100%;
        align-self: center;
    `}
    ${media.between("744px", "large")`
        min-width: 326px;
        max-width: 565px;
    `}
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 588.63px;
    padding-left: 87px;
    ${media.lessThan("743px")`
        width: 100%;
        padding-left: 0px;
        min-width: 300px;
        justify-content: flex-start;
    `}
    ${media.between("744px", "large")`
        width: 70%;
        max-width: 565px;
        height: auto;
        padding-left: 3%;
    `}
    ${media.greaterThan("large")`
    width: 50%;
    `}
    `;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 362px;
    height: 568px;
    justify-self: center;
    justify-content: center;
    ${media.lessThan("743px")`
        height: 100%;
        width: 90%;
        min-width: 290px;
    `}
    ${media.between("744px", "large")`
        height: 31%;
        width: 90%;
        min-width: 320px;
        max-width: 452px;
    `}
    ${media.greaterThan("large")`
        margin-bottom: 21.22px;
    `}
`;

const InnerTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 200px;
    justify-self: flex-start;
    align-self: flex-start;
    justify-content: center;
    align-content: flex-end;
    ${media.lessThan("743px")`
        height: 100%;
        max-height: 280px;
        margin-bottom: 30px;
    `}
    ${media.between("744px", "large")`
        height: 280px;
        width: 100%;
        min-width: 320px;
        max-width: 454px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const Icon = styled.img`
    width: 83px;
    height: auto;
    margin-bottom: 14px;
    transition-duration: 0.5s;
    :hover {
        width:
    }
`;

const H3Responsive = styled(H3)`
    margin-bottom: 13px;
    ${media.lessThan("743px")`
        font-size: 33px;
`}
`;

const ParagraphResponsive = styled(Paragraph)`
    ${media.between("744px", "large")`
        width: 95%;
        max-width: 554px;
    `}
    `;


export default function CoreTechnologies() {

    const [isHovering, setIsHovering] = useState(false)
    const [isHovering2, setIsHovering2] = useState(false)
    const [isHovering3, setIsHovering3] = useState(false)
    
    Aos.init()
        
  return (
    <OuterContainer>
        <H2Responsive>
                Derbi integrates three core technologies into a complete energy resource management package
        </H2Responsive>
        <Container>
            <LeftContainer>
                <Graphic 
                src={GraphicTechnologies}/>
            </LeftContainer>
            <RightContainer>
                <TextContainer
                    data-aos='fade-left'
                    data-aos-duration='500'
                    data-aos-easing='ease-in-out'>
                    <InnerTextContainer
                        onMouseEnter={(e) => {
                            setIsHovering(true)
                        }}
                        onMouseLeave={(e) => {
                            setIsHovering(false)
                        }}>
                        <Icon 
                            style={{ marginBottom: isHovering ? "26px" : "14px"}} src={IconOptimizer} />
                        <H3Responsive>Energy Optimizer</H3Responsive>
                        <ParagraphResponsive>Engage in regional energy markets with optimized delivery of locally stored energy based on live market data.</ParagraphResponsive>
                    </InnerTextContainer>
                </TextContainer>
                <TextContainer
                    data-aos='fade-left'
                    data-aos-duration='500'
                    data-aos-delay='500'
                    data-aos-easing='ease-in-out'>
                    <InnerTextContainer
                        onMouseEnter={(e) => {
                            setIsHovering2(true)
                        }}
                        onMouseLeave={(e) => {
                            setIsHovering2(false)
                        }}>
                        <Icon 
                            style={{ marginBottom: isHovering2 ? "26px" : "14px"}} src={IconPowerPlant} />
                        <H3Responsive>Virtual Power Plant</H3Responsive>
                        <ParagraphResponsive>Aggregate and operate diverse energy resources as a single virtual power plant for interfacing with regional power grids.</ParagraphResponsive>
                    </InnerTextContainer>
                </TextContainer>
                <TextContainer
                    data-aos='fade-left'
                    data-aos-duration='500'
                    data-aos-delay='500'
                    data-aos-easing='ease-in-out'>
                    <InnerTextContainer
                        onMouseEnter={(e) => {
                            setIsHovering3(true)
                        }}
                        onMouseLeave={(e) => {
                            setIsHovering3(false)
                        }}>
                        <Icon 
                            style={{ marginBottom: isHovering3 ? "26px" : "14px"}} src={IconDerms} />
                        <H3Responsive>DERMS</H3Responsive>
                        <ParagraphResponsive>Remotely manage distributed energy resources at any scale for robust energy delivery solutions.</ParagraphResponsive>
                    </InnerTextContainer>
                </TextContainer>
            </RightContainer>
        </Container>
    </OuterContainer>
  )
}