import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1, H2, H3, MobileH1, Paragraph } from '../../styles/fonts';
import Background from "./../../images/bg_faqs.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const OuterContainer = styled.div`
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    background-image: url(${Background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    width: 100vw;
    flex-direction: column;
    height: 100%;
    margin-top: 95px;
    z-index: -1;
`

const BgGrey = styled.div`
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 12px;
    z-index: -1;
    justify-content: center;
    align-content: center;
    align-self: center;
    ${media.lessThan("743px")`
        margin-left: 15px;
        margin-right: 15px;
    `}
    ${media.between("744px", "1170px")`
        margin-left: 20px;
        margin-right: 20px;
    `}
    ${media.greaterThan("large")`
        max-width: 894px;
    `}
`;

const H1Responsive = styled(H1)`
        max-width: 489px;
        color: ${COLORS.Blue};
    ${media.lessThan("743px")`
        font-size: 39px;
        max-width: 289px;
        margin-top: 36px;
    `}
    ${media.between("744px", "large")`
        margin-top: 87px;
    `}
    ${media.greaterThan("large")`
        margin-top: 87px;
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width:  auto;
    height: 100%;
    align-self: center;
    ${media.lessThan("743px")`
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 22px;
    `}
    ${media.greaterThan("large")`
        max-width: 1198px;
        margin-top: 51px;
    `}
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    ${media.lessThan("743px")`
        margin-right: 0px;
    `}
    ${media.between("744px", "large")`
        margin-top: 42px;
    `}
    `;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 64px;
    justify-content: center;
    align-self: center;
    ${media.lessThan("743px")`
        margin-bottom: 22px;
        min-width: 290px;
    `}
    ${media.greaterThan("large")`
        max-width: 437px;
    `}
`;

const H3Responsive = styled(H3)`
    margin-bottom: 16px;
    font-size: 26px;
`;

export default function FAQs() {

    Aos.init()

  return (
    <OuterContainer>
        <BgGrey
            data-aos='fade-up'
            data-aos-duration='500'
            data-aos-easing='ease-in-out'>
            <H1Responsive>FAQs</H1Responsive>
            <Container>
                <RightContainer>
                    <TextContainer>
                        <H3Responsive>Question text goes here</H3Responsive>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</Paragraph>
                    </TextContainer>
                    <TextContainer>
                        <H3Responsive>Question text goes here</H3Responsive>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</Paragraph>
                    </TextContainer>
                </RightContainer>
                <RightContainer>
                    <TextContainer>
                        <H3Responsive>Question text goes here</H3Responsive>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</Paragraph>
                    </TextContainer>
                    <TextContainer>
                        <H3Responsive>Question text goes here</H3Responsive>
                        <Paragraph>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.</Paragraph>
                    </TextContainer>
                </RightContainer>
            </Container>
        </BgGrey>
    </OuterContainer>
  )
}