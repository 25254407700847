import React, { useState } from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1, ButtonText } from '../../styles/fonts';
import ImageHeroBg from "./../../images/hero-image.png"
import ImageHeroBgTablet from "./../../images/hero.jpg"
import ImageHeroBgMobile from "./../../images/hero-mobile.png"
import ImageLogo from "./../../images/logo-colour.svg"
import IconImage from "./../../images/ic_arrow_right.svg"
import { Link } from 'react-scroll'
import Aos from "aos"
import "aos/dist/aos.css"

const OuterContainer = styled.div`
  grid-area: 1 / 1 / 2 / 2;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    height: 100%;
`

const Container = styled.div`
    display: flex;
    justify-content: center;
`

const LogoInnerContainer = styled.div`
    display: flex;
    flex-direction: column; 
`

const LogoOuterContainer = styled.div`
    display: flex;
    flex-direction: row; 
`

const BgBlueBox = styled.div`
    /* left: 0; */
    position: absolute;
    background: ${COLORS.Dark};
    width: 100%;
    height: 375px;
    z-index: -1;
    margin-right: 600px;
    max-width: 1159px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    ${media.lessThan("743px")`
        width: 90%;
        height: 334px;
        margin-right: 0px;
        left: 0;
        border-bottom-left-radius: 0px;
    `}
    ${media.between("744px", "large")`
        width: 70%;
        margin-right: 0px;
        left: 0;
        border-bottom-left-radius: 0px;
        `}
`;

const HeroImage = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    background-image: url(${ImageHeroBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: absolute;
    margin-right: 30px;
    margin-left: 30px;
    overflow: none;
    min-width: 794px;
    width: 1380px;
    height: 745px;
    margin-top: 40px;
    ${media.lessThan("743px")`
            border-radius: 12px;
            background-image: url(${ImageHeroBgTablet});
            width: 100%;
            min-width: 190px;
            height: auto;
            min-height: 392px;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 49.14px;
            `}
        ${media.between("744px", "large")`
            background-image: url(${ImageHeroBgTablet});
            border-radius: 12px;
            width: 100%;
            min-width: 700px;
            height: 100%;
            min-height: 570px;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 20px;
        `}
`;

const HeroLogo = styled.img`
    width: 155px;
    height: 234px;
    margin-left: 48px;
    margin-top: 20px;
    ${media.lessThan("743px")`
        width: 82px;
        height: 123px;
        background: ${COLORS.Dark};
        padding: 26px;
        padding-right: 30px;
        padding-top: 10px;
        margin-left: -1px;
        margin-top: -1px;
        border-bottom-right-radius: 12px;
    `}
    ${media.between("744px", "large")`
        width: 122px;
        height: 185px;  
        background: ${COLORS.Dark};
        padding: 40px;
        margin-left: -1px;
        margin-top: -1px;
        border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    `}
`

const ContainerTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 550px;
    min-width: 550px;
    margin-left: 400px;
    ${media.lessThan("743px")`
        display: none;
    `}
    ${media.between("744px", "1170px")`
        display: none;
    `}
`;

const TopLeftInvertedRadius = styled.div`
  background-color: transparent;
  height: 50px;
  width: 50px;
  border-top-left-radius: 12px;
  box-shadow: 0 -25px 0 0 ${COLORS.Dark}; /* This is where the magic happens! */
    ${media.greaterThan("1171px")`
        display: none;
    `}
`

const ContainerTitleMobile = styled.div`
    display: flex;
    flex-direction: column;
    ${media.lessThan("743px")`
        width: 90%;
        min-width: 322px;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 25px;
    `}
    ${media.between("744px", "1170px")`
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
        width: auto;
    `}
    ${media.greaterThan("1171px")`
        display: none;
    `}
`;

const H1Responsive = styled(H1)`
    ${media.lessThan("743px")`
        font-size: 39px;
        max-width: 440px;
    `}
    ${media.between("744px", "1170px")`
        font-size: 52px;
        width: 660px;
    `}
    ${media.greaterThan("1170px")`
        font-size: 52px;
        width: 650px;
    `}
`;

const BtnContact = styled.button`
    background: ${COLORS.Blue};
    border-radius: 12px;
    border: none;
    width: 274px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16.9606px 33.9213px;
    gap: 11.31px;
    margin-top: 50px;
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
    ${media.lessThan("743px")`
        margin-top: 25px;
    `}
    ${media.between("744px", "large")`
        margin-top: 36px;
    `}
`;

const ButtonTextResponsive = styled(ButtonText)`
    transition-duration: 0.5s;
    transition-timing-function: ease-in;
`;

const Icon = styled.img`
  width: 15px;
  height: 15px;
  align-self: center;
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
`

export default function Hero() {

    const [isHovering, setIsHovering] = useState(false)

        Aos.init()

  return (
    <OuterContainer>
        <Container>
            <BgBlueBox />

            <HeroImage>
                <LogoOuterContainer>
                    <LogoInnerContainer>
                        <HeroLogo src={ImageLogo}/>    
                        <TopLeftInvertedRadius/>
                    </LogoInnerContainer>
                    <TopLeftInvertedRadius/>
                </LogoOuterContainer>

                <ContainerTitle
                    data-aos='zoom-in'
                    data-aos-duration='1000'
                    data-aos-delay='500'
                    data-aos-easing='ease-in-out'>
                    <H1Responsive>Maximize energy value and deliver intelligent power control solutions</H1Responsive>
                    <Link activeClass="active" to="contact" spy={true} smooth={true} duration={2000}>
                        <BtnContact
                            style={{cursor: isHovering ? "pointer" : "none"}}
                            onMouseEnter={(e) => {
                                setIsHovering(true)
                            }}
                            onMouseLeave={(e) => {
                                setIsHovering(false)
                            }}>
                            <ButtonTextResponsive
                                style={{ marginRight: isHovering ? "16px" : "0px", transitionTimingFunction: "ease-in"}}>
                                Get in Touch
                            </ButtonTextResponsive>
                            <Icon style={{opacity: isHovering ? 1 : 0, }} src={IconImage} />
                        </BtnContact>
                    </Link>
                </ContainerTitle>
            </HeroImage>
        </Container>
            <ContainerTitleMobile
                data-aos='fade-up'
                data-aos-duration='1000'
                data-aos-delay='500'
                data-aos-easing='ease-in-out'>
                <H1Responsive>Maximize energy value and deliver intelligent power control solutions</H1Responsive>
                <Link activeClass="active" to="contact" spy={true} smooth={true} duration={2000}>
                    <BtnContact
                        style={{cursor: isHovering ? "pointer" : "none"}}
                        onMouseEnter={(e) => {
                            setIsHovering(true)
                        }}
                        onMouseLeave={(e) => {
                            setIsHovering(false)
                        }}>
                        <ButtonText
                            style={{ marginRight: isHovering ? "16px" : "0px"}}>
                            Get in Touch
                        </ButtonText>
                        <Icon style={{opacity: isHovering ? 1 : 0, }} src={IconImage} />
                    </BtnContact>
                </Link>
            </ContainerTitleMobile>
    </OuterContainer>
  )
}