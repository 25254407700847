import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1, Paragraph, H4 } from '../../styles/fonts';
import ContactForm from './ContactForm';
import ImageLogo from "./../../images/logo-white.svg"


const OuterContainer = styled.div`
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    background: ${COLORS.Dark};
    width: 100%;
    justify-self: center;
    flex-direction: column;
    height: 100%;
    margin-top: 95px;
    padding-bottom: 146px;
    ${media.lessThan("743px")`
    margin-top: 56px;
    padding-bottom: 73px;
    `}
    ${media.between("744px", "large")`
    padding-bottom: 40px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BlueCard = styled.div`
    display: flex;
    background: ${COLORS.Dark};
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-self: center;
`;

const BgBlue = styled.div`
    grid-area: 5 / 1 / 6 / 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-self: center;
    ${media.lessThan("743px")`
        margin-right: 15px;
        margin-left: 15px;
        width: 90%;
    `}
    ${media.between("744px", "1170px")`
        margin-left: 20px;
        margin-right: 20px;
        max-width: 590px;
    `}
    ${media.greaterThan("large")`
        max-width: 1058px;
        min-width: 1111px;
        margin-left: 125px;
    `}
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const H1Responsive = styled(H1)`
        max-width: 489px;
        color: ${COLORS.White};
        font-size: 50px;
    ${media.lessThan("743px")`
        max-width: 289px;
        margin-top: 36px;
        font-size: 39px;
    `}
    ${media.between("744px", "large")`
        margin-top: 97px;
    `}
    ${media.greaterThan("large")`
        margin-top: 97px;
    `}
`;

const H2Responsive = styled(H4)`
    font-size: 26px;
    color: ${COLORS.White};
    font-weight: 500;
    ${media.lessThan("743px")`
        font-weight: 400;
        line-height: 123%;
        letter-spacing: -0.025em;
    `}
    ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:  auto;
    flex-wrap: wrap;
    ${media.lessThan("743px")`
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 67px;
    `}
    ${media.between("744px", "large")`
        flex-direction: column;
        margin-top: 43px;
    `}
    ${media.greaterThan("large")`
        max-width: 1008px;
        margin-top: 51px;
    `}
`

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-wrap: wrap;
    ${media.lessThan("743px")`
        margin-left: 36px;
        margin-top: 46px;
    `}
    ${media.between("744px", "large")`
        margin-top: 71px;
    `}
    ${media.greaterThan("large")`
    `}
`;


const HeroLogo = styled.img`
    width: 95px;
    height: 143px;
    justify-self: flex-end;

`

const ParagraphResponsive = styled(Paragraph)`
        font-size: 10px;
        width: 219px;
        margin-top: 36px;
`;


export default function Contact() {

  return (
    <OuterContainer id="contact">
        <BlueCard>
            <BgBlue>
                <TitleContainer>
                    <H1Responsive>Contact Us</H1Responsive>
                </TitleContainer>
                <Container>
                    <ContactForm />
                    <LogoContainer>
                        <HeroLogo src={ImageLogo} />  
                        <ParagraphResponsive>© 2022 Derbi Corp. Trademarks and brands are the property of their respective owners.</ParagraphResponsive>
                    </LogoContainer>
                </Container>
            </BgBlue>
        </BlueCard>
    </OuterContainer>
  )
}