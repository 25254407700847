import React from 'react';
import styled from "styled-components";
import { COLORS } from '../../styles/colors';
import Contact from './Contact';
import CoreTechnologies from './CoreTechnologies';
import Customers from './Customers';
import FAQs from './FAQs';
import Hero from './Hero';
import Visual from './Visual';

const GridContainer = styled.div`
  display: grid;
  grid-template: auto auto auto auto / 100vw;
  overflow: hidden;
  background-color: ${COLORS.white};
  z-index: -1;
`

export default function Layout() {

  return (
    <GridContainer>
        <Hero />
        <CoreTechnologies />
        <Visual />
        <Customers />
        {/* <FAQs /> */}
        <Contact />
    </GridContainer>
  )
}