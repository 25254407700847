import React, { useState } from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1, H4, Paragraph } from '../../styles/fonts';
import GraphicTechnologies from "./../../images/couple-no-graphic.png"
import BlueBoxImage from "./../../images/couple-shape.svg"
import Aos from "aos"
import "aos/dist/aos.css"

const OuterContainer = styled.div`
    grid-area: 4 / 1 / 5 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:  auto;
    height: 100%;
    margin-top: 95px;
    justify-self: center;
    margin-right: 30px;
    margin-left: 30px;
    ${media.lessThan("743px")`
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 45px;
        justify-self: flex-start;
        justify-content: flex-start;
        width: 90%;
    `}
    ${media.between("744px", "large")`
        margin-left: 0px;
        margin-right: 0px;
        justify-self: flex-start;
        justify-content: flex-start;
        width: 100%;
    `}
    ${media.greaterThan("large")`
        max-width: 1198px;
        min-width: 1111px;
    `}
`

const H1Responsive = styled(H1)`
    color: ${COLORS.Dark};
    text-align: center;
    align-self: center;
    ${media.lessThan("743px")`
        font-size: 39px;
        max-width: 350px;
    `}
    ${media.between("744px", "large")`
        max-width: 489px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-self: center;
    height: 100%;
    ${media.lessThan("743px")`
        flex-direction: column;
        margin-top: 22px;
        width: 100%;
        min-width: 291px;
        justify-self: flex-start;
        align-content: flex-start;
        align-self: center;
    `}
    ${media.between("744px", "large")`
        flex-direction: column;
        justify-self: flex-start;
        align-content: flex-start;
        width: 45%;
        min-width: 490px;
        align-self: center;
        margin-top: 40px;
    `}
    ${media.greaterThan("large")`
        max-width: 1085px;
        width: 100%;
        align-self: center;
        margin-top: 51px;
        justify-content: space-between;
        align-content: center;

    `}
`

const LeftContainer = styled.div`
    display: grid;
    grid-template: auto / auto;
    height: 100%;
    max-height: 484px;
    ${media.lessThan("743px")`
        min-width: 261px;
        max-height: 270px;
        margin-left: 10px;
        align-self: center;
    `}
    ${media.greaterThan("large")`
        max-width: 522px;
        justify-self: flex-start;
        align-content: flex-start;
    `}
`


const BlueBox = styled.img`
    grid-area: 1 / 1 / 2 / 2;
    z-index: -1;
    position: absolute;
    max-width: 437px;

    ${media.lessThan("743px")`
        max-width: 243px;
        margin-top: 25.5px;
    `}
    ${media.between("744px", "large")`
        margin-top: 51px;
    `}
    ${media.greaterThan("large")`
        margin-top: 51px;
    `}
`;

const Graphic = styled.img`
    grid-area: 1 / 1 / 2 / 2;
    max-width: 484px;
    margin-bottom: 4px;
    transition-duration: 0.5s;
    margin-left: 15px;
    ${media.lessThan("743px")`
        max-width: 267px;
    `}
`;

// Info - Right Container

const RightContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${media.lessThan("743px")`
        margin-top: 62px;
    `}
    ${media.between("744px", "large")`
        margin-top: 75px;
    `}
    ${media.greaterThan("large")`
        max-width: 500px;
        justify-self: flex-end;
    `}
    `;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 31px;
    justify-content: center;
    align-self: center;
    ${media.lessThan("743px")`
    max-width: 382px;
    `}
    ${media.between("744px", "large")`
    max-width: 362px;

    `}
    ${media.greaterThan("large")`
        margin-bottom: 66px;
        max-width: 437px;
    `}
`;

const H4Responsive = styled(H4)`
    color: ${COLORS.Dark};
    margin-bottom: 13px;
    font-size: 26px;
    max-width: 435px;
`;

const ParagraphResponsive = styled(Paragraph)`
        ${media.lessThan("743px")`
    `}
    ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;


export default function Customers() {

    const [isHovering, setIsHovering] = useState(false)

    Aos.init()

  return (
    <OuterContainer>
        <H1Responsive>Your customers matter to us</H1Responsive>

        <Container>
            <LeftContainer
>
                <BlueBox 
                src={BlueBoxImage} />
                <Graphic 
                    onMouseEnter={(e) => {
                        setIsHovering(true)
                    }}
                    onMouseLeave={(e) => {
                        setIsHovering(false)
                    }}
                    style={{ paddingLeft: isHovering ? "30px" : "15px"}} src={GraphicTechnologies} />
            </LeftContainer>
            <RightContainer>
                <TextContainer
                    data-aos='fade-up'
                    data-aos-duration='500'
                    data-aos-easing='ease-in-out'>
                    <H4Responsive>Empower your customers through access</H4Responsive>
                    <ParagraphResponsive>Derbi provides retail consumers with access to the wholesale electricity market. As an aggregated community, your customers will have minimized import rates and maximized export rates.</ParagraphResponsive>
                </TextContainer>
                <TextContainer
                    data-aos='fade-up'
                    data-aos-duration='500'
                    data-aos-easing='ease-in-out'>
                    <H4Responsive>Helping your customers save time and money</H4Responsive>
                    <ParagraphResponsive>Derbi optimizes energy resource selection to save your customers money. With Derbi’s EV charger integration, EV owners will see greater savings and increased convenience charging from home.</ParagraphResponsive>
                </TextContainer>
            </RightContainer>
        </Container>
    </OuterContainer>
  )
}