import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { COLORS } from '../../styles/colors';
import { H1 } from '../../styles/fonts';
import ImageWoman from "./../../images/woman-image.png"
import ImageWomanTablet from "./../../images/woman-tablet.png"
import ImageWomanMobile from "./../../images/woman-mobile.png"
import Aos from "aos"
import "aos/dist/aos.css"


const OuterContainer = styled.div`
    grid-area: 3 / 1 / 4 / 2;
    display: flex;
    flex-direction: column; 
    height: 100%;
    margin-top: 100px;
`
const Container = styled.div`
    display: flex;
    justify-content: center;
    ${media.lessThan("743px")`
        flex-direction: column;
        align-content: center;
        justify-content: flex-start;
    `}
    ${media.between("744px", "1170px")`
    `}
`

const BgBlueBox = styled.div`
    display: flex;
    position: absolute;
    background: ${COLORS.Dark};
    width: 85%;
    max-width: 1198px;
    min-height: 375px;
    margin-top: 100px;
    border-radius: 12px;
    z-index: -1;
    ${media.lessThan("743px")`
        width: 80%;
        height: 5%;
        min-height: 210px;
        align-self: center;
        justify-self: center;
        margin-top: 0px;
        max-width: 703px;
        justify-content: center;
    `}
    ${media.between("744px", "1170px")`
        width: 80%;
        max-height: 450px;
        height: 50%;
        `}
        ${media.greaterThan("1170px")`
        max-height: 525px;
        height: 100%;
    `}
`;


const H2Responsive = styled(H1)`
    color: ${COLORS.Blue};
    ${media.lessThan("743px")`
        font-size: 30px;
        font-weight: 400;
        padding-left: 0px;
        max-width: 280px;
        align-self: center;
        text-align: center;
    `}
    ${media.between("744px", "1170px")`
        font-size: 36px;
        font-weight: 400;
        width: 337px;
        padding-top: 84px;
        padding-left: 84px;
    `}
    ${media.greaterThan("1170px")`
        width: 487px;
        padding-top: 84px;
        padding-left: 64px;
    `}
`;

const HeroImage = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    background-image: url(${ImageWoman});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 30px;
    margin-left: 30px;
    overflow: none;
    min-width: 794px;
    width: 1380px;
    height: 734px;
    border-radius: 12px;
        ${media.lessThan("743px")`
            background-image: url(${ImageWomanMobile});
            background-position: bottom right;
            background-size: cover;
            width: auto;
            min-width: 190px;
            height: auto;
            min-height: 392px;
            margin-left: 15px;
            margin-right: 15px;
            margin-top: 199.14px;
            `}
        ${media.between("744px", "large")`
            background-position: top center;
            background-image: url(${ImageWomanTablet});
            background-size: cover;
            width: 100%;
            min-width: 700px;
            height: 100%;
            min-height: 570px;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 20px;
        `}
`;

export default function Visual() {

    Aos.init()

  return (
    <OuterContainer>
        <Container
            data-aos='fade-in'
            data-aos-duration='500'
            data-aos-easing='ease-in-out'>
            <BgBlueBox>
                    <H2Responsive>100% green retail VPP technology</H2Responsive>
            </BgBlueBox>
            <HeroImage/>
        </Container>
    </OuterContainer>
  )
}