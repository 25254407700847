import './App.css';
import { Reset } from "styled-reset"
import Layout from './ui/landing/Layout';

function App() {
  return (
    <div>
      <Reset/>
      <Layout/>
    </div>
  );
}

export default App;
