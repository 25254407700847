import React, { useState, useEffect } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import { ButtonText } from '../../styles/fonts';
import { COLORS } from "./../../styles/colors"
import autosize from "autosize";

const Form = styled.form`
  width: 590px;
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
    width: 97%;
  `}
`

const Input = styled.input`
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: 'Roboto', sans-serif;    
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  background-color: transparent;
  border-bottom: 1px solid ${COLORS.TextGrey};
  margin-top: 28px;
  padding-bottom: 5px;
  padding-top: 10px;
  :focus {
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLORS.White};
}
`

const InputMessage = styled.textarea`
  -webkit-appearance: none;
  border-radius: 0;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: 'Roboto', sans-serif;    
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  background-color: transparent;
  border-bottom: 1px solid ${COLORS.TextGrey};
  margin-top: 28px;
  padding-bottom: 5px;
  padding-top: 10px;
  resize: none;
  /* min-height: 25px; */
  /* max-height: 100px; */
  /* height: auto; */
  :focus {
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${COLORS.White};
}
`

const BtnContact = styled.button`
    background: ${COLORS.Blue};
    border-radius: 12px;
    border: none;
    width: 274px;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16.9606px 33.9213px;
    gap: 11.31px;
    margin-top: 49px;
    color: ${COLORS.White};
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17.61px;
    line-height: 150%;
`;

const Error = styled.p`
  color: red;
  margin-top: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
`

const Success = styled.p`
  color: white;
  margin-top: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  width: 272px;
`

export default function ContactForm() {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const [disabled, setDisabled] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    autosize(document.querySelector('textarea'))
  }, []);

  const onSubmit = async (data) => {
    setDisabled(true)

    try {
      let result = await fetch(
            " https://stvbtpvjtj.execute-api.us-west-2.amazonaws.com/prod/derbi-contact-form",   // hits derbi AWS Lambda 
        {
        method: "post",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          fullname: data.fullname,
          email: data.email,
          message: data.message,
        }),
      })
      reset({ fullname: "", email: "", message: "" })

      var textarea = document.getElementById('targetArea');
      textarea.setAttribute('style','');
      textarea.value = "";

      setDisabled(false)
    } catch (e) {
      console.log("Error", e)
      setDisabled(false)
    }
  }

  return (
    <div>
      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Input 
            type='text' 
            name='fullname' 
            placeholder="Name" 
            {...register("fullname", { required: true, maxLength: 45 })} />
        {errors.fullname && <Error>Username required</Error>}

        <Input
          type='text'
          name='email'
          placeholder="Email"
          {...register("email", {required: true, maxLength: 50,pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,})}
        />
        {errors.email && <Error>Email required and must be valid</Error>}

        <InputMessage 
            id="targetArea"
            type='textarea' 
            rows={1} name='message' 
            placeholder="Message"
            {...register("message", {required: true, maxLength: 500 })} />
            {errors.message && <Error>Message required</Error>}

        <BtnContact disabled={disabled} type='submit' style={{opacity: disabled ? "0.8" : "1.0", cursor: isHovering ? "pointer" : "none"}}
            onMouseEnter={(e) => {
              setIsHovering(true)
          }}
          onMouseLeave={(e) => {
              setIsHovering(false)
          }}
        >
          <ButtonText>
                  Submit
          </ButtonText>
        </BtnContact>
        {isSubmitSuccessful ? <Success>Message Sent</Success> : null}
      </Form>
    </div>
  )
}
