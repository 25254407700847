import styled from "styled-components";
import { COLORS } from "./colors";

export const H1 = styled.h1`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 122.5%;
    letter-spacing: -0.025em;
    color: ${COLORS.Dark};
`;

export const H2 = styled.h2`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 123%;
    letter-spacing: -0.025em;
    color: ${COLORS.MediumBlue};
`;

export const H3 = styled.h3`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 33px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: ${COLORS.Dark};
`;

export const H4 = styled.h4`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: ${COLORS.Dark};
`;

export const Paragraph = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 150%;
    color: ${COLORS.TextGrey};
`;

export const MobileH1 = styled.h1`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 39px;
    line-height: 110%;
    letter-spacing: -0.025em;
    color: ${COLORS.Dark};
`;

export const MobileH2 = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 123%;
    letter-spacing: -0.025em;
    color: ${COLORS.MediumBlue};
`;

export const ButtonText = styled.h2`
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17.61px;
    line-height: 150%;
    color: ${COLORS.White};
`;